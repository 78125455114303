import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import VideoModal from "../../components/videoModal";

import withLocation from "../../components/withLocation";

class NothingnessCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "tab1",
      videoModalOpen: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  componentDidMount() {
    if (this.props.search.page === "runway") {
      this.setState({ activeTab: "tab3" });
    }
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  toggleVideoModal(e) {
    this.setState((prevState) => ({
      videoModalOpen: !prevState.videoModalOpen,
    }));
  }

  render() {
    return (
      <Layout>
        <SEO title="Nothingness Collection" />

        <section className="hero-banner">
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.banner.childImageSharp.fluid} />
              <h1 className="hero-banner__title">
                N O T H I N G N E S S &nbsp; Collection
              </h1>
              <h2 className="hero-banner__subtitle">
                Nothingness is my way of trying to depict the meaning of reality
                and the mystery of identity. Also trying to explore the origins
                of the beliefs and intuitions which shape and order my vision of
                life away from the trivia of daily life, attached to objects or
                values which are so irrelevant.
                <br />
                <br />
                I found the inspiration in the mystery and the inexplicable of
                Virginia Woolf’s characters, rarely enclosed in precise
                outlines. Also, LS Lowry’s interpretation of everyday existence
                where he did not celebrate his subjects but rather capturing a
                ‘way of life’, a monotonous existence of ghostly people as a
                whole, unconsciously commemorating the lost working-class dress
                codes of 1920s and 30s.
                <br />
                <br />
                The Collection have resulted in an absence of coherence, a
                random assemble of fragments of life. I believe the individual
                identity is always altering and changing its shape in response
                to the forces surrounding it. What I create today, I should not
                create in a year’s time.
                <br />
                <br />
                Yet, finally the fragments do arrange themselves into a
                meaningful order.
              </h2>
            </div>
          </div>
        </section>

        <button
          onClick={this.toggleVideoModal}
          className="watch-video-btn button is-dark"
        >
          Watch Video
        </button>
        <VideoModal
          videoId="A1FjeNf85-w"
          videoModalOpen={this.state.videoModalOpen}
          toggleVideoModal={this.toggleVideoModal}
        />

        <div className="tabs is-centered">
          <ul>
            <li
              className={this.state.activeTab === "tab1" ? " is-active " : " "}
              onClick={() => this.toggleTab("tab1")}
            >
              <a>Campaign</a>
            </li>
            <li
              className={this.state.activeTab === "tab2" ? " is-active " : " "}
              onClick={() => this.toggleTab("tab2")}
            >
              <a>Look book</a>
            </li>
          </ul>
        </div>

        <section className="collection-wrapper">
          <div id="tab-content">
            <div
              className={this.state.activeTab === "tab1" ? " is-active " : " "}
              data-content="1"
            >
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0376.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0482.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0540.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2568.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2742.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2745.childImageSharp.fluid} />
                </div>
              </div>
            </div>

            <div
              className={this.state.activeTab === "tab2" ? " is-active " : " "}
              data-content="2"
            >
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2432.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2390.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2386.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2394.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2396.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2387.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2454.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2456.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2457.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2463.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2528.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2530.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2541.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2576.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2586.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2593.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2626.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2633.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2643.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2649.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2652.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2669.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2692.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2697.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2702.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2706.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2762.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2771.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2798.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2799.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2813.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2835.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2843.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2848.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2860.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2896.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2906.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2911.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2914.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_2924.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_2936.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_3012.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_3020.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_3029.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_3030.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_3034.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_3040.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_3045.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

NothingnessCollection.propTypes = {};

export default withLocation(NothingnessCollection);

export const fluidImagePassageThroughTimeBanner = graphql`
  fragment fluidImagePassageThroughTimeBanner on File {
    childImageSharp {
      fluid(maxWidth: 2560) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const fluidImagePassageThroughTime = graphql`
  fragment fluidImagePassageThroughTime on File {
    childImageSharp {
      fluid(maxWidth: 1280) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    banner: file(
      relativePath: { eq: "collections/nothingness/campaign/banner.jpg" }
    ) {
      ...fluidImagePassageThroughTimeBanner
    }
    IMG_0376: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_0376.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_0482: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_0482.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_0540: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_0540.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2568: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_2568.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2742: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_2742.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2745: file(
      relativePath: { eq: "collections/nothingness/campaign/IMG_2745.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2386: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2386.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2387: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2387.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2390: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2390.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2394: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2394.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2396: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2396.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2432: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2432.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2454: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2454.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2456: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2456.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2457: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2457.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2463: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2463.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2528: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2528.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2530: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2530.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2541: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2541.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2576: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2576.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2586: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2586.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2593: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2593.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2626: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2626.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2633: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2633.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2643: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2643.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2649: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2649.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2652: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2652.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2669: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2669.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2692: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2692.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2697: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2697.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2702: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2702.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2706: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2706.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2762: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2762.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2771: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2771.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2798: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2798.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2799: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2799.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2813: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2813.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2835: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2835.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2843: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2843.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2848: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2848.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2860: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2860.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2896: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2896.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2906: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2906.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2911: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2911.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2914: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2914.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2924: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2924.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_2936: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_2936.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3012: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3012.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3020: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3020.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3029: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3029.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3030: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3030.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3034: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3034.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3040: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3040.jpg" }
    ) {
      ...fluidImageAbyss
    }
    IMG_3045: file(
      relativePath: { eq: "collections/nothingness/lookbook/IMG_3045.jpg" }
    ) {
      ...fluidImageAbyss
    }
  }
`;
